import React, { useState } from "react";
import { graphql, StaticQuery, Link } from "gatsby";
import { SocialIcons } from "./footer";
import buildRetriever from "../utils/slugRetriever";
import { FiMinus, FiPlus } from "react-icons/fi";

const Drawer = ({ getCategory, social, open, setOpen, onClose, data: { allCatsJson: { edges } } }) => {
    const handleClose = () => {
        onClose && onClose();
    }
    const categories = edges;
    const cls = open ? "open" : ""
    return (
        <div className={`app-drawer ${cls}`} >
            <ul className="contents">
                <SocialIcons key="mobile-social" social={social} mobile />
                {
                    categories.filter(({ node }) => {
                        // return true
                        return !!node.toplevel
                    }).sort((a, b) => a.index - b.index).map(({ node }, ndx) => {
                        if (node.subs && node.subs.length) {
                            const subs = node.subs.map(getCategory);
                            return node.title && <Accordion key={ndx} className="list-item" node={node} items={subs} />

                        } else {
                            return node.title && <li key={ndx} className="list-item"><Link to={`/${node.title.toLowerCase().replace(/\s/g, "_")}`}>{node.title}</Link></li>
                        }
                    })
                }
                <hr />
                <li className="list-item" onClick={() => setOpen(false)}>Close X</li>
            </ul>
        </div >
    );
}

const Accordion = ({ node, items, ...props }) => {
    const [open, setOpen] = useState(false)
    return (
        <li className="dropdown">
            <button onClick={() => { setOpen(!open) }} className="title">
                <span>
                    {node.title}
                </span>
                {open ? <FiMinus /> : <FiPlus />}
            </button>
            {open && <div className={`sub-content ${open ? "open" : ""}`}>
                <ul className="sub-links">
                    {items.map((it, index) => {
                        return <li>
                            <Link key={index} to={`/${it.title.toLowerCase().replace(/\s/g, "_")}`}>{it.title}</Link>
                        </li>
                    })}
                </ul>
            </div>}
        </li >
    )
}


export default (props) => {
    return (
        <StaticQuery
            query={query}
            render={data => <Drawer data={data} {...props} getCategory={buildRetriever(data.allCatsJson.edges.map(({ node }) => node))} />}
        />
    )
}

export const query = graphql`
query {
    allCatsJson {
        edges {
            node {
                title
                description
                toplevel
                subs 
                index
                fields {
                    slug
                }
            }
        }
    }
}
`