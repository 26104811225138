import React from "react";
import { FiTwitter, FiInstagram, Fi, FiFacebook, FiMail } from "react-icons/fi";

export const SocialIcons = ({ social, mobile }) => {
    return (
        <div className={`social-icons${mobile ? "-mobile" : ""}`}>
            <a href={social.twitter} rel="noopener noreferrer" target="_blank" className="icon">
                {/* <span className="icon"> */}
                <FiTwitter />
                {/* </span> */}
            </a>
            <a href={social.insta} rel="noopener noreferrer" target="_blank" className="icon">
                {/* <span className="icon"> */}
                <FiInstagram />
                {/* </span> */}
            </a>
            <a href={social.fb} rel="noopener noreferrer" target="_blank" className="icon">
                {/* <span className="icon"> */}
                <FiFacebook />
                {/* </span> */}
            </a>
            <a href={`mailto:${social.email}`} rel="noopener noreferrer" target="_blank" className="icon">
                {/* <span className="icon"> */}
                <FiMail />
                {/* </span> */}
            </a>
        </div>
    )
}

export default ({ social, desc, disclaimer }) => {
    return (
        <footer className="footer">
            <span className="text">
                Stay in Touch
                <p className="desc">{desc}</p>
            </span>
            <SocialIcons social={social} />
            <div className="contact">
                <form
                    className="email"
                    name="subscribe"
                    method="POST"
                    netlify
                    data-netlify="true"
                    netlify-honeypot="bot-field">
                    <input type="hidden" name="form-name" value="subscribe" />
                    <input type="text" name="email" placeholder="Email Address" />
                    <input type="submit" value="Subscribe" />
                </form>
            </div>
            <div className="copyright">
                © {new Date().getFullYear()} | TheFrontRow.vip
                <small>{disclaimer} | Built with ❤ by <a href="https://joshuanaz.me" target="__blank">Joshua Nazareth</a></small>
            </div>
        </footer>
    )
}