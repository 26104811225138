import React, { useState } from "react";
import { graphql, StaticQuery, Link } from "gatsby";
import { SocialIcons } from "./footer";
import buildRetriever from "../utils/slugRetriever";

const NavBar = ({ data: { allCatsJson: { edges: categories } }, social, background, getCategory }) => {
    return (
        <div className={`grid-container nav-bar ${background ? "background" : ""}`}>
            <ul className="links">
                {
                    categories.filter(({ node }) => {
                        // return true
                        return !!(node.toplevel)
                    }).sort((a, b) => a.index - b.index).map(({ node }, ndx) => {
                        if (node.subs && node.subs.length) {
                            const subs = node.subs.map(getCategory);
                            return node.title && <Dropdown key={ndx} className="list-item" node={node} items={subs} />

                        } else {
                            return node.title && <li key={ndx} className="list-item"><Link to={`/${node.title.toLowerCase().replace(/\s/g, "_")}`}>{node.title}</Link></li>
                        }
                    })
                }
                {/* <li className="list-item"><a>About</a></li> */}
                {/* <li className="list-item"><a>Contact Us</a></li> */}
            </ul>
            <SocialIcons social={social} />
        </div>
    );
}

const Dropdown = ({ node, items, ...props }) => {

    const [open, setOpen] = useState(false)

    const hoverHandle = (inn) => () => {
        setOpen(inn)
    }

    return (
        <li {...props}>
            <span onMouseEnter={hoverHandle(true)}>{node.title} <small>▼</small></span>
            { open && <div className="dropdown">
                {items.map((it, index) => {
                    return <Link key={index} to={`/${it.title.toLowerCase().replace(/\s/g, "_")}`}>{it.title}</Link>
                })}
            </div>}
        </li>
    )
}

export default (props) => {
    return (
        <StaticQuery
            query={query}
            render={data => <NavBar data={data} {...props} getCategory={buildRetriever(data.allCatsJson.edges.map(({ node }) => node))} />}
        />
    )
}

export const query = graphql`
query {
    allCatsJson {
        edges {
            node {
                title
                description
                toplevel
                subs 
                index
                fields {
                    slug
                }
            }
        }
    }
}
`