import { graphql, StaticQuery } from "gatsby";
import Img from "gatsby-image";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import React from "react";
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import "../assets/sass/main.scss";
import Drawer from "./appdrawer";
import NavBar from "./navbar";
import Footer, { SocialIcons } from "./footer";




const PostNav = ({ type, targetPost }) => {
  let symbol = (type === "next" ? < FaArrowLeft /> : <FaArrowRight />)
  let dir = (type === "next" ? "right" : "left")
  return (
    <span className={`${type} postNavLink`}>
      <AniLink cover duration={0.5} bg="#8a8a8a" direction={dir} to={targetPost.fields.slug} rel={type}>
        <span className="symbol">{symbol}</span>
        <span className="postNavTitle">{targetPost.frontmatter.title}</span>
      </AniLink>
    </span>
  );

}

const Layout = ({ location, title, children, previous, next, titleColor, blogList, socialLinks, metadata, logo }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  let isHomepage = blogList || (location.pathname === rootPath);
  let header = isHomepage ?
    [
      <AniLink key="header" fade duration={0.1} to="/">
        <h1 className="homeHeader">
          <div className="logo">
            <Img fluid={logo.childImageSharp.fluid} />
          </div>
        </h1>
      </AniLink>,
      // <SocialIcons key="social" social={socialLinks} />,
    ] :
    [
      <AniLink key="header" fade duration={0.1} to="/">
        <div className="logo">
          <Img fluid={logo.childImageSharp.fluid} />
        </div>
      </AniLink>
      ,
      previous && (
        <PostNav key="prev" targetPost={previous} type="prev" />
      )
      ,
      next && (
        <PostNav key="next" targetPost={next} type="next" />
      )
    ]
  const [isMenuOpen, setOpen] = React.useState(false);
  return (
    <React.Fragment>
      <header className="header">
        <nav className={`${isMenuOpen ? "open" : ""}`}>
        </nav>
        {header}
        <div className={`menu ${isMenuOpen ? "open" : ""}`} onClick={() => setOpen(!isMenuOpen)}>
          Menu ▸
          </div>
        {/* <span className="spacer"></span> */}
      </header>
      <NavBar social={socialLinks} background={!isHomepage} />
      <div className={`grid-container main ${isHomepage ? "homepage" : "blogPage"}`}>
        <div>
          <main>{children}</main>
        </div>

      </div>
      <Footer social={socialLinks} desc={metadata.desc} disclaimer={metadata.footer} />
      <Drawer social={socialLinks} open={isMenuOpen} setOpen={setOpen} />
    </React.Fragment>
  )
}

export default (props) => (
  <StaticQuery
    query={query}
    render={({ social, meta, logo }) => <Layout logo={logo} socialLinks={social.nodes[0]} metadata={meta.nodes[0]} {...props} />}
  />
)

export const query = graphql`
query {
  social: allDataJson(filter: {title: {eq: "social"}}) {
    nodes {
      twitter,
      fb,
      insta,
      email
    }
  }
  meta: allDataJson(filter: {title: {eq: "meta"}}) {
    nodes {
      desc
      footer
    }
  }
  logo: file(relativePath: {glob: "logo.png"}) {
    childImageSharp {
      fixed(width: 250, height: 250) {
        ...GatsbyImageSharpFixed
      }
      fluid(maxWidth: 1250) {
        ...GatsbyImageSharpFluid
      }
    }
  }
}
`


